import React, { useState, useEffect } from "react";
import {
    Button,
    Pagination,
    Divider,
    Grid,
    Card,
    CardContent,
    CardHeader,
    CardMeta,
    Dropdown,
    Input,
    Icon,
    Modal, Label, Accordion, Menu, Loader, Form, Select, Popup, Image, Segment, Sticky
} from "semantic-ui-react";
import { NoData, convertTimeToUTCAndIST, getUser, shortTitleLength, verifiedTick } from "../../../utils/common"
import { notifyToast } from "../../../utils/toast";
import { stopLoader } from "../../../utils/sweetAlert";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GET, GETWITHPDFDOWN, POST, PUT } from "../../../Services";
import moment from "moment";
import momentTz from "moment-timezone";

let pageLimit = 25;
let filterCount = 0;

const pageRequest = {
    pageNumber: 0,
    pageLimit: pageLimit,
    filterValue: {},
    search: "",
    sort: {},
}
const defaultFilters = {
    startDate: "",
    endDate: "",
    status: "",
    payment: "",
    celebrity: "",
    refundStatus: 5
}

const statusOptions = [
    { key: "Failed", text: "Failed", value: 0 },
    { key: "success", text: "Success", value: 1 },
    { key: "Pending", text: "Pending", value: 2 },
    { key: "Intiated", text: "Intiated", value: 4 },
];

const celebrityOptions = [
    { key: "verified", text: "Verified", value: "verified", label: { color: "green", empty: true, circular: true }, },
    { key: "unverified", text: "Unverified", value: "unverified", label: { color: "grey", empty: true, circular: true }, },
];


const refundOptions = [
    { key: 0, text: "No refund request", value: 0 },
    { key: 1, text: "Refund requested", value: 1 },
    { key: 2, text: "Refund approved", value: 2 },
    { key: 3, text: "Refund declined", value: 3 },
    { key: 4, text: "Refund reversed", value: 4 },
    { key: 5, text: "All", value: 5 },
]

const filterData = [
    {
        key: "Success",
        text: "Success",
        value: "success",
        label: { color: "green", empty: true, circular: true },
    },
    {
        key: "Pending",
        text: "Pending",
        value: "pending",
        label: { color: "yellow", empty: true, circular: true },
    },
    {
        key: "Failed",
        text: "Failed",
        value: "fail",
        label: { color: "red", empty: true, circular: true },
    },
    {
        key: "Initiated",
        text: "Initiated",
        value: "initiated",
        label: { color: "orange", empty: true, circular: true },
    }
];

const OrderHistory = () => {
    const [orderHistoryData, setOrderHistoryData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [sortingUser, setSortingUser] = useState(false);
    const [pagiActivePage, setPagiActivePage] = useState(1);
    const [manualSerach, setManualSearch] = useState("");
    const [filterObj, setFilterObj] = useState(defaultFilters);
    const [filterModal, setFilterModal] = useState(false);
    const [filterLabel, setFilterLabel] = useState("Filter");
    const [activeIndex, setActiveIndex] = useState(0);
    const [loader, setLoader] = useState(undefined);
    const [totalRecords, setTotalRecords] = useState(0);
    const [approveModal, setApproveModal] = useState(false);
    const [updateStatus, setUpdateStatus] = useState({});
    const [selctedItem, setSelectedItem] = useState({});
    const [adminActionModal, setAdminActionModal] = useState(false);
    const [verifyModal, setVerifyModal] = useState(false);
    const [TFAFlag, setTFAFlag] = useState(0);
    const [otpFlag, setOtpFlag] = useState(false);
    const [otpObj, setOtpObj] = useState({ otp: "" });
    const [viewAdminActions, setAdminActions] = useState([])
    const loggedInUser = getUser()
    let userRole = loggedInUser.role;
    let history = useHistory()
    useEffect(() => {
        function callEff() {
            pageRequest.pageNumber = 0;
            pageRequest.pageLimit = 25;
            pageRequest.filterValue = {};
            pageRequest.search = "";
            pageRequest.sort = {};
            filterCount = 0
            fetchOrderHistory({ pageNum: 0 });
        }
        callEff();
    }, []);

    const fetchOrderHistory = async () => {
        setLoader(undefined)
        let { status, totalRecords, totalPages, message, payload } = await POST(
            "/v1/user/order-history",
            {
                ...pageRequest
            }
        );
        stopLoader();

        if (status === 0) {
            setOrderHistoryData([]);
            setTotalRecords()
            setLoader(0)
            return console.log(message);
        }
        setTimeout(() => {
            setTotalPages(0);
            setLoader(status)
            setTotalPages(totalPages);
            setTotalRecords(totalRecords);
            setOrderHistoryData(payload);
        }, 12);
    };
    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    };

    const selectDate = async (e, data, type) => {
        try {
            if (type === "startDate") {
                setFilterObj((pre) => ({
                    ...pre,
                    startDate: data.value
                }))
            } else {
                setFilterObj((pre) => ({
                    ...pre,
                    endDate: data.value
                }))
                if (filterObj.startDate === "" || filterObj.endDate === "") {
                    filterCount = filterCount + 1;

                }
            }
        } catch (er) { console.log(er); };
    };

    const applyFilters = (e) => {
        if ((filterObj.startDate !== '' && filterObj.endDate === '') || (filterObj.startDate === '' && filterObj.endDate !== '')) {
            return notifyToast("Please Select Start And End Date Both", "error", "bottom-right", 2500);
        }
        pageRequest.filterValue = filterObj
        fetchOrderHistory();
        setFilterModal(false)
    };
    const clearFilters = () => {
        pageRequest.filterValue = {
            startDate: '',
            endDate: '',
            status: '',
            payment: '',
            celebrity: '',
            refundStatus: 5
        }
        setFilterLabel("Filter")
        setFilterObj((pre) => ({
            ...pre,
            startDate: '',
            endDate: '',
            status: '',
            payment: '',
            celebrity: '',
            refundStatus: 5
        }))
        filterCount = 0
        fetchOrderHistory();
        setFilterModal(false)
    };
    const closeFilterModal = () => {
        setFilterObj((pre) => ({
            ...pre,
            startDate: '',
            endDate: '',
            status: '',
            payment: '',
            celebrity: '',
            refundStatus: 5
        }))
        filterCount = 0
        setFilterModal(false)
        if (pageRequest.filterValue.startDate !== "" || pageRequest.filterValue.startDate !== "") {
            pageRequest.filterValue = filterObj;
            fetchOrderHistory();
        }
    };
    const openFilterModal = async (item) => {
        try {
            setFilterModal(true);
        } catch (err) {
            console.log(err);
        }
    };

    const handleFilterList = (item, data) => {
        try {
            let filterVal = item.text;
            setFilterLabel(filterVal);
            if (filterObj.status === "") {
                filterCount = filterCount + 1;
            }
            setFilterObj((pre) => ({
                ...pre,
                status: item.value
            }))
        } catch (er) {
            console.log(er);
        }
    };

    const pageChange = async (item, data) => {
        let pageNum = data.activePage - 1;
        setPagiActivePage(data.activePage);
        pageRequest.pageNumber = pageNum
        fetchOrderHistory();
    };
    const onSearch = (e) => {
        let value = e.target.value
        setManualSearch(value)
        if (value.length === 0) {
            pageRequest.search = ""
            fetchOrderHistory();
        }
    };
    const searchOnClick = (e) => {
        const indexOfPlus = manualSerach.indexOf("+");
        if (indexOfPlus === 0) {
            const noSpecialChars = manualSerach.replace(/[^a-zA-Z0-9 ]/, "");
            pageRequest.search = noSpecialChars
        } else {
            pageRequest.search = manualSerach
        }
        pageRequest.pageNumber = 0;
        fetchOrderHistory();
        if (manualSerach === "") {
            pageRequest.search = ""
            fetchOrderHistory();
        }
    };
    const sortUsers = (type) => {
        try {
            if (type === 0) {
                pageRequest.sort = { field: "createdAt", order: 1 }
                fetchOrderHistory();
                setSortingUser(true);
            } else {
                pageRequest.sort = { field: "createdAt", order: -1 }
                fetchOrderHistory();
                setSortingUser(false);
            }
        } catch (er) {
            console.log(er);
        }
    };
    const changeRange = (e, data) => {
        setTimeout(() => {
            setPagiActivePage(1)
            pageRequest.pageNumber = 0;
            pageRequest.pageLimit = data.value
            fetchOrderHistory({ pageNum: 0 });
        }, 0);

    };
    const handleUpdateOrderStatus = (event) => {
        setUpdateStatus((pre) => ({
            ...pre,
            [event.target.name]: event.target.value,
        }));
    };

    const submitApproveRequest = async (item) => {
        let newReason = updateStatus.reason?.trim()
        if (!newReason) {
            return notifyToast(
                "Reason is mandatory",
                "error",
                "bottom-right",
                2500
            );
        }
        if (selctedItem._id) {
            let { status, message } = await PUT(
                "/coin/update-coin-order",
                selctedItem._id,
                updateStatus
            );
            if (status === 0) {
                return notifyToast(message, "error", "bottom-right", 2500);
            } else {
                fetchOrderHistory({ pageNum: 0 });
                setApproveModal(false)
                setUpdateStatus({})
                return notifyToast(message, "success", "bottom-right", 2500);

            }
        }
    };

    const onSubmit = async () => {
        let { status, message } = await POST('/verif/send-email-otp');
        if (status === 0) {
            setOtpFlag(false)
            return notifyToast(message, 'error', "bottom-right", 2500)
        }
        setOtpFlag(true);
        return notifyToast(message, 'success', "bottom-right", 2500)
    }
    const submitOtp = async () => {
        history.push("/add-cheer-coins")
        let { status, message } = await POST('/verif/verify-email-otp', otpObj);
        if (status === 0) {
            return notifyToast(message, 'error', "bottom-right", 2500)
        }

        setTFAFlag(status)
        if (status === 0) {
        }

        setVerifyModal(true)
        return notifyToast(message, 'success', "bottom-right", 2500)
    }
    const handleOtpChange = (event) => {
        setOtpObj((pre) => ({
            ...pre,
            otp: event.target.value
        }))
    };

    const dropPagiOptions = [
        { key: 1, text: 25, value: 25, }, { key: 2, text: 50, value: 50, },
        { key: 3, text: 75, value: 75, }, { key: 4, text: 100, value: 100, },
    ];


    return (
        <>


            <h2 style={{ marginTop: "0px", marginBottom: "0" }}>
                Order History
                <Label basic horizontal color='teal'> Total : {totalRecords}</Label>

            </h2>
            <Divider inverted style={{ paddingBottom: 5 }} />
            <Grid style={{ width: "100%" }}>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Button.Group>
                            <Button icon labelPosition='left' onClick={() => openFilterModal()}>
                                <Icon name='filter' />
                                Filters
                                {filterCount === 0 ? null : <Label color="teal" size="mini" circular floating horizontal style={{ left: "120%", borderRadius: "15px" }}>
                                    {filterCount}
                                </Label>}
                            </Button>
                            {filterCount === 0 ? null : <Button color="red" icon onClick={filterCount > 0 ? () => clearFilters() : null}>
                                <Icon name="close" />
                            </Button>}
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Input type='text' onKeyPress={(e) => {
                            if (e.key === "Enter") { return searchOnClick(); }
                        }}
                            value={manualSerach} placeholder='Search...' action onChange={onSearch}>
                            <input />
                            <Button type='submit' onClick={searchOnClick} icon><Icon name="search" fitted /></Button>
                        </Input>
                    </Grid.Column>
                    <Grid.Column width={1}>
                        {sortingUser === false ? (
                            <Icon
                                name="sort amount up"
                                titile="Latest Users"
                                className="icon-font-size"
                                onClick={() => sortUsers(0)}
                            />
                        ) : (
                            <Icon
                                name="sort amount down"
                                titile="Oldest Users"
                                className="icon-font-size"
                                onClick={() => sortUsers(1)}
                            />
                        )}
                    </Grid.Column>
                    <Grid.Column width={6} style={{ textAlign: "right" }}>
                        <Pagination
                            boundaryRange={1}
                            activePage={pagiActivePage}
                            siblingRange={0}
                            pointing
                            secondary
                            totalPages={totalPages}
                            onPageChange={(event, data) => pageChange(event, data)}
                        />
                    </Grid.Column>

                    <Grid.Column width={2} style={{ textAlign: "right", paddingTop: 13 }}>
                        <Dropdown
                            placeholder="25"
                            options={dropPagiOptions}
                            onChange={(e, data) => changeRange(e, data)}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />


            {/* <Grid className="resp-header" as="b" columns="equal" textAlign="center" verticalAlign="middle" style={{ marginLeft: "-50px" }}>
                        <Grid.Column>User</Grid.Column>
                        <Grid.Column>E-mail</Grid.Column>
                        <Grid.Column>Mobile</Grid.Column>
                        <Grid.Column>Details</Grid.Column>
                        <Grid.Column>Status</Grid.Column>
                        <Grid.Column>Date<br /><small>(Order Date)</small></Grid.Column>
                    </Grid> */}


            <Card.Group itemsPerRow={4}>
                <>
                    {
                        loader === undefined ? <Loader active >Loading...</Loader> : loader === 0 ? <NoData /> :
                            orderHistoryData &&
                            orderHistoryData.map((item, index) => {
                                return (
                                    <Card key={index}>
                                        <CardContent>
                                            <Label className="circular-label" circular>{index + 1}</Label>
                                            <CardHeader>
                                                {shortTitleLength(item.title, 25)}
                                                {item?.userInfo?.image ?
                                                    <>
                                                        <Image
                                                            floated='left'
                                                            circular={true}
                                                            size='mini'
                                                            src={item?.userInfo?.image}
                                                            style={{ height: "50px", width: "50px", objectFit: "cover" }}
                                                        />
                                                        <Label>{item?.userInfo?.firstName} {item?.userInfo?.lastName}</Label>
                                                    </>
                                                    :
                                                    <>
                                                        <Label>
                                                            <Icon color="red" fitted name="ban" /> User Deleted
                                                        </Label>
                                                    </>
                                                }
                                            </CardHeader>
                                        </CardContent>
                                        <CardContent>
                                            <CardMeta>
                                                Email : {item?.userInfo?.email}
                                            </CardMeta>
                                            <CardMeta>
                                                Mob : {item?.userInfo?.mobile}
                                            </CardMeta>
                                            <CardMeta>Transaction : {item?.transactionId}</CardMeta>
                                            <CardMeta>Amount : {item?.amount} {item?.currency}</CardMeta>
                                            <CardMeta>Order : {item?.orderId}</CardMeta>
                                            <CardMeta>
                                                <strong>Status :</strong>
                                                <Label className="ui tag label" color={item?.status === 1 ? "green" : item?.status === 2 ? "orange" : "yellow"}>
                                                    {item?.status == 4 ? "Failed" : item?.status == 2 ? "Pending" : item?.status == 1 ? "Success" : "Initialize"}
                                                </Label>

                                            </CardMeta>
                                            <CardMeta>
                                                {momentTz.utc(item.createdAt).tz("Asia/Kolkata").format("DD-MM-YYYY hh:mm:ss A [IST]")}
                                            </CardMeta>
                                        </CardContent>
                                    </Card>
                                );
                            })}
                </>
            </Card.Group>


            <Modal
                size={"tiny"}
                open={filterModal}
                onClose={() => setFilterModal(false)}
                closeIcon
            >
                <Modal.Header>Filters</Modal.Header>
                <Modal.Content>
                    <Accordion>
                        <Accordion.Title
                            active={activeIndex === 0}
                            index={0}
                            onClick={handleClick}
                        >
                            <Icon name="dropdown" />
                            Date
                        </Accordion.Title>

                        <Accordion.Content
                            active={activeIndex === 0}
                            style={{ marginLeft: "22px" }}
                        >
                            <Menu tabular>
                                <Menu.Item>
                                    <Input label="From" type="date"
                                        max={moment().format("YYYY-MM-DD")}
                                        value={filterObj.startDate}
                                        onChange={(e, data) => selectDate(e, data, 'startDate')}
                                    />
                                    &nbsp;
                                    <Input label="To" type="date"
                                        max={moment().format("YYYY-MM-DD")}

                                        value={filterObj.endDate}
                                        onChange={(e, data) => selectDate(e, data, 'endDate')}
                                    />
                                </Menu.Item>
                            </Menu>
                        </Accordion.Content>
                        <Accordion.Title
                            active={activeIndex === 1}
                            index={1}
                            onClick={handleClick}
                        >
                            <Icon name="dropdown" />
                            Status
                        </Accordion.Title>

                        <Accordion.Content
                            active={activeIndex === 1}
                            style={{ marginLeft: "22px" }}
                        >
                            <Dropdown

                                text={filterLabel}
                                button
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Menu scrolling>
                                        {filterData.map((item) => {
                                            return (
                                                <Dropdown.Item
                                                    key={item.value}
                                                    {...item}
                                                    onClick={(data) => handleFilterList(item, data)}
                                                />
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Accordion.Content>

                    </Accordion>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="blue" onClick={() => applyFilters()}>
                        Apply
                    </Button>
                    <Button color="red" onClick={() => closeFilterModal(false)}>
                        close
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal
                size="small"
                open={approveModal}
                onClose={() => setApproveModal(false)}
            >
                <Modal.Header>Update Transaction Details</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field
                                control={Select}
                                search
                                label='Select status'
                                value={updateStatus.status}
                                options={statusOptions}
                                onChange={(e, data) => { setUpdateStatus((p) => ({ ...p, 'status': data.value })) }}
                            />
                            <Form.Input
                                fluid
                                label="Payment Id/Order Id"
                                placeholder="Payment Id/Order Id"
                                name="paymentId"
                                value={updateStatus.paymentId}
                                onChange={handleUpdateOrderStatus}
                            />
                            <Form.Input
                                fluid
                                required={true}
                                label="Reason"
                                placeholder="Reason"
                                name="reason"
                                value={updateStatus.reason}
                                onChange={handleUpdateOrderStatus}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color="green" onClick={() => submitApproveRequest()}>
                        Submit
                    </Button>

                    <Button basic color="red" onClick={() => setApproveModal(false)}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal
                size={"tiny"}
                open={adminActionModal}
                onClose={() => setAdminActionModal(false)}
                closeIcon
            >
                <Modal.Header>Admin Actions</Modal.Header>
                <Modal.Content style={{ height: "400px", overflow: "auto" }}>
                    {
                        viewAdminActions.map((item, index) => {
                            return (
                                <div style={{ border: "solid 1px grey" }}>
                                    <p style={{ padding: 5 }}>
                                        <small>Admin Name: </small>
                                        <strong style={{ wordBreak: " break-all" }}>{item.adminName}</strong>
                                    </p>
                                    <p style={{ padding: 5 }}>
                                        <small>Payment Id: </small>
                                        <strong style={{ wordBreak: " break-all" }}>{item.paymentId}</strong>
                                    </p>
                                    <p style={{ padding: 5 }}>
                                        <small>Action: </small>
                                        <strong style={{ wordBreak: " break-all" }}>{item.action}</strong>
                                    </p>
                                    <p style={{ padding: 5 }}>
                                        <small>Old Status: </small>
                                        <strong style={{ wordBreak: " break-all" }}>{item.oldStatus}</strong>
                                    </p>
                                    <p style={{ padding: 5 }}>
                                        <small>New Status: </small>
                                        <strong style={{ wordBreak: " break-all" }}>{item.newStatus}</strong>
                                    </p>
                                    <p style={{ padding: 5 }}>
                                        <small>Reason: </small>
                                        <strong style={{ wordBreak: " break-all" }}>{item.reason}</strong>
                                    </p>
                                    <p style={{ padding: 5 }}>
                                        <small>Date/Time: </small>
                                        <strong style={{ wordBreak: " break-all" }}> {item.createdAt ? moment(new Date(item.createdAt)).format("DD-MM-YYYY, hh:mm:ss A") : ""}</strong>
                                    </p>
                                </div>
                            )
                        })
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button color="red" onClick={() => setAdminActionModal(false)}>
                        close
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal
                size={"tiny"}
                open={verifyModal}
                onClose={() => setVerifyModal(false)}
                closeIcon
            >
                <Modal.Header>{!otpFlag ? "Verify User" : "Enter Otp"}</Modal.Header>
                {
                    !otpFlag ?
                        <>
                            <Modal.Content>
                                <h4 style={{ color: "Highlight" }}>
                                    Please Verify Your Identity!
                                </h4>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive basic onClick={onSubmit}>
                                    Send Otp
                                </Button>

                            </Modal.Actions>
                        </>
                        :
                        <>
                            <Modal.Content>
                                <Form>
                                    <Form.Input
                                        fluid
                                        label='Enter otp'
                                        placeholder='Enter Otp'
                                        name='otp'
                                        value={otpObj.otp}
                                        onChange={handleOtpChange}
                                    />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive basic onClick={() => submitOtp(false)}>
                                    Submit
                                </Button>
                            </Modal.Actions>
                        </>
                }
            </Modal>
        </>
    );
};

export default OrderHistory;
