

export const GETSTUREREJECT = [
    { key: 1, text: 'Gesture Mismatch', value: 1 },
    { key: 2, text: 'Unclear Photo', value: 2 },
    { key: 3, text: 'Low-Quality or Blurred Image', value: 3 },
    { key: 4, text: 'Invalid Submission', value: 4 },
    { key: 5, text: 'Other', value: 5 }
]
export const ERROR = "Something went wrong, Please try again"

export const VERIFICATIONREJECTION = [
    { key: 1, text: 'Unclear Photo', value: 1 },
    { key: 2, text: 'Cropped or Incomplete ID', value: 2 },
    { key: 3, text: 'Details Do Not Match', value: 3 },
    { key: 4, text: 'Invalid Document', value: 4 },
    { key: 5, text: 'Incorrect ID Type Selected', value: 5 },
    { key: 6, text: 'Other', value: 6 }
]

export const DATEFILTER = [
    { key: 1, text: 'Created', value: "createdAt" },
    { key: 2, text: 'Updated', value: "updatedAt" },
]


export const USERIMAGEREJECT = [
    { key: 1, text: "Unclear Image", value: 1, },
    { key: 2, text: "Inappropriate Content", value: 2 },
    { key: 3, text: "Offensive Gestures", value: 3 },
    { key: 4, text: "Underaged Content Detected", value: 4 },
    { key: 5, text: "Altered or Filtered", value: 5 },
    { key: 6, text: "Invalid Photo Uploaded", value: 6 },
    { key: 7, text: "Personal Information Found", value: 7 },
    { key: 8, text: "Duplicate Photo Detected", value: 8 },
    { key: 9, text: "Other", value: 9 },
];

export const GESTUREIMAGES = [
    {
        type: 1,
        image: "https://d1xo93lth1py89.cloudfront.net/userMedia/2024/1727939793060914.png"
    }, {
        type: 2,
        image: "https://d1xo93lth1py89.cloudfront.net/userMedia/2024/17279398438335418.png"
    }, {
        type: 3,
        image: "https://d1xo93lth1py89.cloudfront.net/userMedia/2024/17279398128265760.png"
    }
]


export const LISTOFTOPIC = [
    // { key: 8, text: "Staging Users", value: "pickZonStagings" },
    { key: 1, text: "All Users", value: "pickzonNotification", },
    { key: 2, text: "Premium Users", value: "premium" },
    { key: 3, text: "Premium Plus Users", value: "premiumplus" },
    { key: 4, text: "Approved Users", value: "approvedUsers" },
    { key: 5, text: "Rejected Users", value: "rejectedUsers" },
    { key: 6, text: "Document Verified Users", value: "verificationRequest" },
    { key: 7, text: "Gesture Verified Users", value: "userVerifyGesture" },
    { key: 8, text: "Premium Trial Users", value: "freeTrialPremium" },
    { key: 9, text: "Non Premium Users", value: "NonPremium" }

]

export const LISTOFNOTIFICATION = [
    { key: 1, text: "Your Holiday Match Awaits!🎄", value: "Find someone to share the magic of Christmas with on Pickzon", },
    { key: 2, text: "Christmas Connection Alert!🎅", value: "Swipe and meet your holiday match today! Pickzon is buzzing with festive spirit" },
    { key: 3, text: "🎄 Make the Holidays Magical!", value: "Swipe now and connect with someone who makes this season brighter on Pickzon" },
]

export const ALLFIELDSAREMANDATORY = "All fields are mandatory"
export const SELECTTOPIC = "Please select topic"
export const ETERTITLE = "Please enter title"
export const ENTERDESCRIPTION = "Please description"
export const SUCCESSFULLYSENDNOTIFY = "Notification send successfully"




export const STATUS = {
    New: "#fbbd08",
    Inactive: "red",
    Active: "green"
}

export const GENDER = {
    Man: "#fbbd08",
    Woman: "green",
    Nonbinary: "orange"
}








