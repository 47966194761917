import React from 'react'

const StickyHeader = ({ children }) => {
   return (
      <div style={{
         position: "sticky",
         top: "0px",
         zIndex: 5,
         background: "#fff"
      }}>{children}</div>
   )
}

export default StickyHeader;