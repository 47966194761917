import React, { useState } from "react";
import { Button, Divider, Dropdown, Form, Grid, GridColumn, GridRow, Header, Label, Segment, TextArea } from "semantic-ui-react";
import StickyHeader from "../../component/stickyHeader/StickyHeader";
import { ALLFIELDSAREMANDATORY, ENTERDESCRIPTION, ERROR, ETERTITLE, LISTOFTOPIC, SELECTTOPIC, SUCCESSFULLYSENDNOTIFY } from "../../../utils/constants";
import { notifyToast } from "../../../utils/toast";
import { getUser } from "../../../utils/common";
import { POST } from "../../../Services";
const loggedInUser = getUser()

const PushNotification = () => {

    const [topic, setTopic] = useState();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();

    const sendNotification = async () => {
        if (!title && !topic && !description) { return notifyToast(ALLFIELDSAREMANDATORY, 'error', 'top', 2500) }
        if (!topic) { return notifyToast(SELECTTOPIC, 'error', 'top', 2500) }
        if (!title) { return notifyToast(ETERTITLE, 'error', 'top', 2500) }
        if (!description) { return notifyToast(ENTERDESCRIPTION, 'error', 'top', 2500) }
        const { status } = await POST('/v1/user/send-notification-by-topic', {
            userId: loggedInUser._id,
            topic: topic,
            title: title,
            description: description
        });
        if (status) return notifyToast(SUCCESSFULLYSENDNOTIFY, 'success', "bottom-right", 2500);
        return notifyToast(ERROR, 'error', "bottom-right", 2500)
    }


    return (
        <>
            <StickyHeader>
                <Header as="h2" style={{ paddingTop: 15, paddingLeft: 20 }} > Notification </Header>
                <Divider inverted style={{ paddingBottom: 5, paddingTop: 5 }} />
                <Grid>
                    <GridRow>
                        <GridColumn width={8}>
                            <Grid>
                                <GridRow style={{ justifyContent: 'center' }}>
                                    <GridColumn width={12}>
                                        <Dropdown
                                            placeholder='Select Topic'
                                            fluid
                                            selection
                                            options={LISTOFTOPIC}
                                            onChange={(e, { value }) => setTopic(value)}
                                        />
                                    </GridColumn>
                                </GridRow>

                                <GridRow style={{ justifyContent: 'center' }}>
                                    <GridColumn width={12}>
                                        <Form>
                                            <TextArea
                                                value={title}
                                                placeholder='Enter Title'
                                                onChange={(e, { value }) => setTitle(value)}
                                            />
                                        </Form>
                                    </GridColumn>
                                </GridRow>

                                <GridRow style={{ justifyContent: 'center' }}>
                                    <GridColumn width={12}>
                                        <Form>
                                            <TextArea
                                                value={description}
                                                onChange={(e, { value }) => setDescription(value)}
                                                placeholder='Enter Description' />
                                        </Form>
                                    </GridColumn>
                                </GridRow>

                                <GridRow style={{ justifyContent: 'center' }}>
                                    <GridColumn width={12}>
                                        <Button color='green' onClick={sendNotification}>Send Notification</Button>
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </GridColumn>

                        <GridColumn width={8}>
                            <Segment padded style={{ height: "100%" }}>
                                <Label attached='top'><h4>{title ? title : "Heading !!"}</h4></Label>
                                <h5>{description ? description : "Description...."}</h5>
                            </Segment>
                        </GridColumn>
                    </GridRow>
                </Grid>

            </StickyHeader >
        </>
    );
};

export default PushNotification;
